<template>
  <div>
    <bread-creamb title="Media" />
    <div class="container">
      <div class="page-card-view-box">
        Media Content here
      </div>

    </div>

  </div>
</template>

<script>
import BreadCreamb from './site-pages/BreadCreamb.vue'

export default {
  name: 'Partner',
  components: {
    BreadCreamb,
  },
  data() {
    return {

    }
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
